import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import cn from 'classnames'

import SEO from '../components/seo'
import Section from '../components/Common/Section'
import Container from '../components/Common/Container'
import Typography from '../components/Common/Typography'
import { Button } from '../components/Common/Button'
import RequestDemo from '../components/RequestDemo'
import ContactForm from '../components/contact-form'

import { useTranslations } from '../hooks/use-translations'

import * as s from '../pages/news.module.scss'

const AerialDroneSurvey = () => {
  const { t, getPath } = useTranslations()
  const images = useStaticQuery(imagesQuery)
  const [modal, setModal] = React.useState(false)

  return (
    <>
      <SEO
        title={t('seo_title_aerial_drone_survey')}
        description="Drone surveys significantly enhance construction project management by providing detailed aerial surveys for precise earthwork calculations, documenting progress and compliance, creating accurate 3D models, and producing visually engaging materials."
      />
      <Section>
        <Container>
          <Typography variant="body1" color="gray" style={{ marginBottom: 8 }}>
            {t('date_aerial_drone_survey')}
          </Typography>
          <Typography variant="h1" color="blue" size={40}>
            {t('title_aerial_drone_survey')}
          </Typography>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-1.png"
                alt="Precise earthworks calculation of large areas"
                title="Precise earthworks calculation of large areas"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle2')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text2')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-2.png"
                alt="Recording scopes, stages, and status of completed construction work"
                title="Recording scopes, stages, and status of completed construction work"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle3')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text3')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-3.png"
                alt="Monitoring and comparison of the correct and as-is positioning of engineering communications"
                title="Monitoring and comparison of the correct and as-is positioning of engineering communications"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle4')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text4')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-4.png"
                alt="Monitoring compliance of the performed works with the construction organization project"
                title="Monitoring compliance of the performed works with the construction organization project"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle5')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text5')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-5.png"
                alt="Creating 3D terrain models for design purposes"
                title="Creating 3D terrain models for design purposes"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle6')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text6')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-6.png"
                alt="Overviewing photos and 360 panoramas from a drone"
                title="Overviewing photos and 360 panoramas from a drone"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('aerial_drone_survey_subtitle7')}
            </Typography>
            <Typography variant="body1" size={18}>
              {t('aerial_drone_survey_text7')}
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle8')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text8')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-7.png"
                alt="Recording the fact of completed works"
                title="Recording the fact of completed works"
                placeholder="blurred"
              />
            </div>
          </div>
          <div className={s.margin_top_48}>
            <Typography variant="h2" size={24} mb={16}>
              {t('aerial_drone_survey_subtitle9')}
            </Typography>
            <Typography variant="body1" size={18}>
              {t('aerial_drone_survey_text9')}
            </Typography>
          </div>
          <div className={cn(s.contentWrap, s.margin_top_48)}>
            <div className={s.textWrap}>
              <Typography variant="h2" size={24} mb={16}>
                {t('aerial_drone_survey_subtitle10')}
              </Typography>
              <Typography variant="body1" size={18}>
                {t('aerial_drone_survey_text10')}
              </Typography>
            </div>
            <div className="flex justify-center">
              <StaticImage
                width={564}
                src="../assets/images/solutions/aerial-drone-survey/image-8.png"
                alt="Preparing materials for operational meetings and presentations"
                title="Preparing materials for operational meetings and presentations"
                placeholder="blurred"
              />
            </div>
          </div>
          <Button
            size="large"
            className={cn('mx-auto', s.margin_top_48)}
            onClick={() => navigate(getPath('/digital-services/'))}
          >
            {t('Other digital services')}
          </Button>
        </Container>
      </Section>
      <RequestDemo
        title={t('Know more')}
        bgImage={images.demoBg.childImageSharp.gatsbyImageData}
        moduleData={{
          btnText: t('Request a call'),
          onClick: () => setModal(true),
          btnColor: 'white',
        }}
      />
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t('Request a call')}
          requestType="Request a call"
        />
      )}
    </>
  )
}

export default AerialDroneSurvey

const imagesQuery = graphql`
  query {
    demoBg: file(relativePath: { eq: "services-solutions-demo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
